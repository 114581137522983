import React, { useState, useRef } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CropImage from '@common/src/common/ImageUpload/CropImage';
import PreviewImage from '@hub/components/common/ImageHolders/previewImage';
import AddProductButton from '../../../components/common/buttons/AddProductButton';
import PropTypes from 'prop-types';
import { getStorage, ref as fbRef, uploadBytes } from 'firebase/storage';
import { useSelector } from 'react-redux';
import { IMG_UPLOAD } from '@hub/constants/constants';
import Toggle from '@common/src/common/Toggle/Toggle';
import { handleAsyncBackOperation } from '../helper';

function ChefPicks({ setProductData, productData, handleNext, handleBack, isEdit, editProductData, setEditProductData, setDisableNavigation }) {
    
    const [isChefPick, setIsChefPick] = useState(productData?.chefPick || false);
    const [cropFiles, setCropFiles] = useState(['', '', '', '']);
    const [isImage, setIsImage] = useState([
        !!productData?.chefPickImages?.home?.web,
        !!productData?.chefPickImages?.home?.mobile,
        !!productData?.chefPickImages?.category?.web,
        !!productData?.chefPickImages?.category?.mobile,
    ]);
    const [isLoading, setIsLoading] = useState(false)
    const [isBackLoading, setIsBackLoading] = useState(false);
    const imageRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
    const { selectedCompany } = useSelector((state) => state.auth);

    const validationSchema = Yup.object().shape(
        ['homeWeb', 'homeMobile', 'categoryWeb', 'categoryMobile'].reduce((schema, imageKey, index) => {
            schema[imageKey] = !isImage[index]
                ? Yup.mixed()
                    .test('fileRequired', 'Image is required', (value) => value?.name !== undefined)
                    .test('fileFormat', 'Invalid format', (value) => 
                        !value || IMG_UPLOAD.CHEF_PICKS.FORMATS.includes(value?.type?.split('/')[1])
                    )
                    .test('fileSize', `Size should be maximum ${IMG_UPLOAD.CHEF_PICKS.MAX_SIZE}`, (value) => 
                        !value || value.size <= 1024 * 1024
                    )
                    .required('Image is required')
                : Yup.mixed();
            return schema;
        }, {})
    );

    const handleImageUpload = async (file, index) => {
        const storage = getStorage();
        const path = `company/${selectedCompany?.value}/chefPicks/${['homeWeb', 'homeMobile', 'categoryWeb', 'categoryMobile'][index]}`;
        const storageRef = fbRef(storage, `${path}_${Date.now()}.${file.name.split('.').pop()}`);
        try {
            await uploadBytes(storageRef, cropFiles[index]);
            return `${import.meta.env.VITE_CDN_HOST}${storageRef.fullPath}`
        } catch (error) {
            console.log('Error uploading image:', error);
            return null;
        }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsLoading(true);
        setDisableNavigation(true);
        try {
            const updatedData = { ...productData };
    
            if (isChefPick) {
                const uploadedImages = await uploadAllImages(values);
    
                updatedData.chefPick = true;
                updatedData.chefPickImages = {
                    home: {
                        web: uploadedImages[0] || productData.chefPickImages.home.web,
                        mobile: uploadedImages[1] || productData.chefPickImages.home.mobile,
                    },
                    category: {
                        web: uploadedImages[2] || productData.chefPickImages.category.web,
                        mobile: uploadedImages[3] || productData.chefPickImages.category.mobile,
                    },
                };
            } else {
                updatedData.chefPick = false;
                updatedData.chefPickImages = {
                    home: { web: null, mobile: null },
                    category: { web: null, mobile: null },
                };
            }
    
            updatedData.companyId = selectedCompany?.value;
    
            if (isEdit) {
                setEditProductData({
                    chefPick: updatedData.chefPick,
                    chefPickImages: updatedData.chefPickImages,
                    ...editProductData,
                });
                setProductData((prev) => ({
                    ...prev,
                    chefPick: updatedData.chefPick,
                    chefPickImages: updatedData.chefPickImages,
                    ...editProductData,
                }));
            } else {
                setProductData(updatedData);
            }
            await new Promise((resolve) => setTimeout(resolve, 1000))
            handleNext();
        } catch (error) {
            console.error('Error in submission:', error);
        } finally {
            setIsLoading(false);
            setDisableNavigation(false);
            setSubmitting(false);
        }
    };
    

    const uploadAllImages = async (values) => {
        const uploadPromises = [0, 1, 2, 3].map(async (index) => {
            if (imageRefs[index].current) {
                return await handleImageUpload(values[['homeWeb', 'homeMobile', 'categoryWeb', 'categoryMobile'][index]], index);
            }
            return null;
        });
        return await Promise.all(uploadPromises);
    };

    const renderImageField = (index, label, setFieldValue, values, errors, touched) => {
        // Map the image keys to their respective minResolution
        const imageKeys = ['homeWeb', 'homeMobile', 'categoryWeb', 'categoryMobile'];
        const minResolution = IMG_UPLOAD.CHEF_PICKS[imageKeys[index]].MIN_RESOLUTION;
    
        return (
            <div className="col-span-12">
                <label className="form-label">{label}</label>
                {isImage[index] ? (
                    <div className="relative w-full max-w-xs h-64 border border-gray-300 rounded-md p-2 bg-gray-50 flex items-center justify-center">
                        <PreviewImage
                            src={values[imageKeys[index]]}
                            isRemovable={true}
                            onRemove={() => {
                                setFieldValue(imageKeys[index], undefined);
                                setIsImage((prev) => {
                                    const newIsImage = [...prev];
                                    newIsImage[index] = false;
                                    return newIsImage;
                                });
                            }}
                        />
                    </div>
                ) : (
                    <CropImage
                        value={values[imageKeys[index]]}
                        onChange={(file) => setFieldValue(imageKeys[index], file)}
                        setCropFile={(file) => setCropFiles((prev) => {
                            const newCropFiles = [...prev];
                            newCropFiles[index] = file;
                            return newCropFiles;
                        })}
                        errorText={
                            touched[imageKeys[index]] && errors[imageKeys[index]]
                                ? errors[imageKeys[index]]
                                : null
                        }
                        ref={imageRefs[index]}
                        path={`company/${selectedCompany?.value}/chefPicks`}
                        minCrop={minResolution} // Pass the dynamic minResolution here
                    />
                )}
            </div>
        );
    };

    const initialValues = {
        homeWeb: productData?.chefPickImages?.home?.web || null,
        homeMobile: productData?.chefPickImages?.home?.mobile || null,
        categoryWeb: productData?.chefPickImages?.category?.web || null,
        categoryMobile: productData?.chefPickImages?.category?.mobile || null,
    };

    const handleBackWithLoader = async () => {
        handleAsyncBackOperation(handleBack, setIsBackLoading, setDisableNavigation);
      };

    return (
      <Formik initialValues={initialValues} validationSchema={isChefPick && validationSchema} onSubmit={handleSubmit}>
        {({ setFieldValue, errors, touched, values }) => (
          <Form>
            <span className="text-sm text-gray-500">Make this product a Chef's Pick</span>
            <div className="flex items-center mb-4">
              <Toggle
                label="Chef's Pick"
                value={isChefPick}
                onChange={(e) => setIsChefPick(e.target.checked)}
                className="mr-4"
              />
            </div>
            {isChefPick && (
              <div className="grid grid-cols-2 gap-6">
                {['Home', 'Category'].map((section, sectionIndex) => (
                  <div key={section} className="bg-white rounded p-6">
                    <h3 className="text-lg font-semibold mb-4">{section}</h3>
                    {['Web', 'Mobile'].map((platform, platformIndex) => {
                      const index = sectionIndex * 2 + platformIndex
                      return renderImageField(index, platform, setFieldValue, values, errors, touched)
                    })}
                  </div>
                ))}
              </div>
            )}
            <AddProductButton
              className="mt-4"
              handleBack={handleBackWithLoader}
              handleNext={() => handleSubmit(values)}
              loadingBack={isBackLoading}
              loadingNext={isLoading}
            />
          </Form>
        )}
      </Formik>
    )
}

ChefPicks.propTypes = {
    setProductData: PropTypes.func.isRequired,
    productData: PropTypes.object.isRequired,
    handleNext: PropTypes.func.isRequired,
    isEdit: PropTypes.bool,
    editProductData: PropTypes.object,
    setEditProductData: PropTypes.func,
    handleBack: PropTypes.func,
    setDisableNavigation: PropTypes.func
};

export default ChefPicks;