import { useState, useRef, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '@common/src/pageheader/pageheader'
import TableList from '@common/src/common/Table/TableList'
import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas'
import { db } from '../../firebase/firebaseapi'
import { COLLECTIONS, STATUS } from '@common/src/constants/constants'
import { useSelector } from 'react-redux'
import BannerForm from './bannerForm'
import RowImage from '@hub/components/common/ImageHolders/rowImage'
import { collection, getDocs, orderBy, query } from 'firebase/firestore'
import { getStatus, timeStamptoDate } from '@common/src/common/helper'
import TextBreaker from '@common/src/common/Table/textBreaker'

const pagination = {
    pageSize: 20,
}

const Banner = () => {
    const tableRef = useRef(null)
    const [data, setData] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const { selectedCompany } = useSelector((state) => state.auth)
    const [dbRef, setDbRef] = useState(null)
    const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()

    useEffect(() => {
        if (selectedCompany) {
            setDbRef(collection(db, `${COLLECTIONS.COMPANIES}/${selectedCompany.value}/${COLLECTIONS.BANNERS}`))
        }
    }, [selectedCompany])

    const onEditClick = (data) => {
        setData(data)
        setIsEdit(true)
        openOffCanvas('editBanner', 'Edit Banner')
    }
    const onComplete = () => {
        if (tableRef.current) {
            tableRef.current.refreshCurrentPage()
            closeOffCanvas()
        }
    }
    const onAddClick = () => {
        setData(null)
        setIsEdit(false)
        openOffCanvas('addBanner', 'Add Banner')
    }
    const getBannerStatus = (endDate, startDate, status) => {
        return getStatus(startDate, endDate, selectedCompany.timeZone, status);
    };
    const getColums = (permissions, onEditClick) => {
        return [
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'desc',
                render: (text) => (
                    <TextBreaker text={text} />
                ),
            },
            {
                title: 'App',
                dataIndex: 'app',
                key: 'appBanner',
                render: (_, row) => <RowImage src={row.app} />,
            },
            {
                title: 'Web App',
                dataIndex: 'Web',
                key: 'webBanner',
                render: (_, row) => <RowImage src={row.web} />,
            },
            {
                title: 'Start Date',
                dataIndex: 'startDate',
                key: 'startDate',
                render: (_, row) => <>{timeStamptoDate(row.startDate, selectedCompany?.timeZone, 'DD/MM/YYYY hh:mm A')}</>,
            },
            {
                title: 'End Date',
                dataIndex: 'endDate',
                key: 'endDate',
                render: (_, row) => <>{timeStamptoDate(row.endDate, selectedCompany?.timeZone, 'DD/MM/YYYY hh:mm A')}</>,
            },
            {
                title:'Live/Offline',
                dataIndex: 'liveOrOffline', 
                key: 'liveOrOffline',
                render: (_, row) => <>{getBannerStatus(row.endDate,row.startDate, row.status)}</>

            },
            { title: 'Sort Order', dataIndex: 'sortOrder', key: 'sortOrder', render: (_, row) => <>{row.sortOrder}</> },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (_, row) => <>{row.status}</>
            },
            {
                title: 'Action',
                key: 'action',
                className: 'text-start',
                render: (_, record) => (
                    <div className="flex flex-row items-center !gap-2 text-[0.9375rem]">
                        <Link
                            aria-label="anchor"
                            to="#"
                            onClick={() => onEditClick(record)}
                            className="ti-btn ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-info/10 text-info hover:bg-info hover:text-white hover:border-info"
                        >
                            <i className="ri-pencil-line"></i>
                        </Link>

                    </div>
                ),
            },
        ]
    }
    const columns = useMemo(() => getColums('permissions', onEditClick), [])
    const handleBannerTypeChange = async (type) => {
        try {
            let collection_name = '';

            if (type === 'CATEGORY') {
                collection_name = COLLECTIONS.CATEGORIES;
            }
            else if (type === 'PRODUCT') {
                collection_name = COLLECTIONS.PRODUCTS;
            }

            // Ensure a company is selected before proceeding
            if (!selectedCompany) {
                console.error('Please select a company first.');
                return null;
            }

            // Create a reference to the database collection
            const dbRef = collection(
                db,
                `${COLLECTIONS.COMPANIES}/${selectedCompany.value}/${collection_name}`
            );

            // Query the database and fetch documents
            const dataQuery = query(dbRef, orderBy('createdAt', 'desc'));
            const dataSnapShot = await getDocs(dataQuery);
            // Map to an array of objects with `id` and `name` for the select box
            const data = dataSnapShot.docs.map((doc) => ({
                value: `${type}/${doc.id}`,
                label: doc.data().name?.en, // Assuming the document has a `name` field
                isDisabled: doc.data().status === STATUS.INACTIVE,
            }));
            // Return the processed data
            return data;
        } catch (error) {
            console.error('Error fetching banner type data:', error);
            throw error; // Re-throw the error if necessary
        }
    };


    return (
        <>
            <PageHeader currentpage="Banners" activepage="Promotions" mainpage="Banner" />
            <TableList
                name="Banners"
                columns={columns}
                dbRef={dbRef}
                pagination={pagination}
                tools={{
                    btnName: 'Add Banner',
                    btnIcon: 'ri-add-line',
                    btnClick: onAddClick,
                }}
                ref={tableRef} />
            <FloatingCanvas
                id={'hs-test-123'}
                onClose={() => closeOffCanvas(false)}
                isVisible={isVisible}
                close={closeOffCanvas}
                title={offCanvasTitle}
                okText="Submit"
                closeText="Close"
            >

                {['addBanner', 'editBanner'].includes(activeComponent) && (
                    <BannerForm data={data} isEdit={isEdit} onComplete={onComplete} onBannerTypeChange={handleBannerTypeChange} />
                )}
            </FloatingCanvas>
        </>
    )
}
export default Banner

