import CropImage from '@common/src/common/ImageUpload/CropImage'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import AddProductButton from '../../../components/common/buttons/AddProductButton'
import PropTypes from 'prop-types'
import { getStorage, ref as fbRef, uploadBytes } from 'firebase/storage'
import { useRef, useState } from 'react'
import PreviewImage from '@hub/components/common/ImageHolders/previewImage'
import { useSelector } from 'react-redux'
import { IMG_UPLOAD } from '@hub/constants/constants'
import { handleAsyncBackOperation } from '../helper'

const ImageUplaod = ({
  handleNext,
  handleBack,
  setProductData,
  productData,
  isEdit,
  editProductData,
  setEditProductData,
  setDisableNavigation,
}) => {
  const imageRef = useRef(null)
  const { selectedCompany } = useSelector((state) => state.auth)
  const [cropFile, setCropFile] = useState('')
  const [isImage, setIsImage] = useState(!!productData?.image?.[0])
  const [isLoading, setIsLoading] = useState(false)
  const [isBackLoading, setIsBackLoading] = useState(false);

  const validationSchema = Yup.object({
    image:
      !isImage &&
      Yup.mixed()
        .test('image', 'Product image is required', (value) => value?.name !== undefined)
        .test(
          'image',
          'Format should be jpeg or png or jpg',
          (value) => !value || IMG_UPLOAD.PRODUCT_IMAGE.FORMATS.includes(value?.type?.split('/')[1])
        )
        .test(
          'image',
          `Size should be maximum ${IMG_UPLOAD.PRODUCT_IMAGE.MAX_SIZE}`,
          (value) => !value || value.size <= 1024 * 1024
        )
        .required('Product image is required'),
  })

  const handleProductImageUpload = async (file) => {
    // Initialize Firebase storage
    const storage = getStorage()
    // Generate a unique string for the file name
    const randomString = Date.now()
    // Construct the file path
    let path = `company/${selectedCompany?.value}/products/image_bnr_`
    const storageRef = fbRef(storage, `${path}_${randomString}.${file.path.split('.').pop()}`)

    // Upload the file to Firebase storage
    const uploadTask = uploadBytes(storageRef, cropFile)

    try {
      // Wait for the upload to complete and get the download URL
      await uploadTask
      return `${import.meta.env.VITE_CDN_HOST}${storageRef.fullPath}`
    } catch (error) {
      // Log any errors that occur during upload
      console.log('error', error)
      return null
    }
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    let imageUrl = values.image;
    setIsLoading(true); // Start loader
    setDisableNavigation(true);
  
    try {
      if (imageRef.current && !isImage) {
        // Upload the image if it's new
        imageUrl = await handleProductImageUpload(values.image);
      }
  
      // Update productData and editProductData if needed
      const updatedData = { ...productData, image: [imageUrl] };
      setProductData(updatedData);
      if (isEdit && imageRef.current && !isImage) {
        setEditProductData({ ...editProductData, image: [imageUrl] });
      }
  
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate processing delay
      handleNext(); // Proceed to the next step
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsLoading(false); // Stop loader
      setDisableNavigation(false);
    }
  };
  const handleBackLoader = async () => {
    handleAsyncBackOperation(handleBack, setIsBackLoading, setDisableNavigation);
  };
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="xl:col-span-12 col-span-12">
        <div className="box">
          <div className="box-body add-products !p-0">
            <div className="p-6">
              <Formik
                initialValues={{
                  image: productData?.image?.[0] || '',
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue, errors, touched, values }) => (
                  <Form>
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 col-span-12">
                        <div className="box">
                          <div className="box-body add-products !p-0">
                            <div className="grid grid-cols-1 gap-6">
                              <label className="form-label" htmlFor="image">
                                Product Image<span style={{ color: 'red' }}>*</span>
                              </label>
                              {isImage ? (
                                <div className="h-64 w-64 m-auto">
                                  <PreviewImage
                                    src={values.image}
                                    isRemovable={true}
                                    onRemove={() => {
                                      setFieldValue('image', undefined)
                                      setIsImage(false)
                                    }}
                                  />
                                </div>
                              ) : (
                                <CropImage
                                  value={values.image}
                                  onChange={(file) => setFieldValue('image', file)}
                                  setCropFile={(file) => setCropFile(file)}
                                  errorText={errors.image}
                                  ref={imageRef}
                                  path={`company/${selectedCompany?.value}/products/image_bnr_`}
                                  minCrop={IMG_UPLOAD.PRODUCT_IMAGE.MIN_RESOLUTION}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <AddProductButton
                      handleBack={handleBackLoader}
                      handleNext={handleSubmit}
                      loadingBack={isBackLoading}
                      loadingNext={isLoading}
                    />  
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ImageUplaod.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  setProductData: PropTypes.func,
  productData: PropTypes.object,
  isEdit: PropTypes.bool,
  editProductData: PropTypes.object,
  setEditProductData: PropTypes.func,
  setDisableNavigation: PropTypes.func,
}

export default ImageUplaod
