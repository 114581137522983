import {
  collection,
  getDocs,
  query,
  where,
  doc,
  writeBatch,
  Timestamp,
  runTransaction,
  getDoc,
} from 'firebase/firestore'
import logger from '@common/src/lib/logger'
import { db } from '../../firebase/firebaseapi'
import { COLLECTIONS } from '../../constants/constants'

export const getCompany = async (all, ids) => {
  try {
    let snap

    if (all) {
      snap = await getDocs(collection(db, COLLECTIONS.COMPANIES))
    } else if (ids && Array.isArray(ids) && ids.length > 0) {
      const rolesQuery = query(collection(db, COLLECTIONS.COMPANIES), where('id', 'in', ids))
      snap = await getDocs(rolesQuery)
    } else {
      logger.warn('No valid ids provided for filtering.')
      return [] // Return an empty array if no valid ids are provided
    }

    // Map the documents to the desired format
    const data = snap.docs.map((doc) => {
      return ({
        value: parseInt(doc?.id),
        label: doc?.data()?.name?.en,
        timeZone: doc?.data()?.config?.country?.timezone || 'Asia/Kolkata',
      })
    })

    return data
  } catch (error) {
    logger.error('Error fetching user data or roles:', error)
    return [] // Return an empty array in case of error
  }
}

/**
 * Trigger banner actions (add/edit) on Firestore
 * @param {Object} bannerInfo - banner info to be added/edited
 * @param {boolean} isEdit - true if editing existing banner, false if adding new banner
 * @returns {Promise} - resolves with the result of the action, rejects with the error
 */
export const triggerBannerActions = async (bannerInfo, isEdit) => {
  bannerInfo.type = 'BANNER_1'
  if (bannerInfo?.startDate) {
    bannerInfo.startDate = Timestamp.fromDate(new Date(bannerInfo.startDate))
  }
  if (bannerInfo?.endDate) {
    bannerInfo.endDate = Timestamp.fromDate(new Date(bannerInfo.endDate))
  }
  try {
    const companyId = bannerInfo.companyId
    // References to company collections
    const bannersRef = collection(db, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.BANNERS}`)
    const companyHomeRef = collection(db, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.HOME}`)
    const storesRef = collection(db, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.STORES}`)
    const storeDocs = await getDocs(storesRef)
    // Using Batch for Adding
    const batch = writeBatch(db)
    if (isEdit) {
      // Using Transaction for Atomicity)
      const bannerDocRef = doc(bannersRef, bannerInfo.id)
      const bannerDocSnap = await getDoc(bannerDocRef)
      const bannerExistData = bannerDocSnap.data()
      const companyHomeDocRef = doc(companyHomeRef, `BANNER_${bannerInfo.id}`)
      let bannerData = {};
      let bannerDataForHome = {};
      // Loop through bannerInfo entries and log them
      Object.entries(bannerInfo).forEach(([key, value]) => {
        bannerData[`data.${key}`] = value;
        bannerDataForHome[key] = value;
      });
      await runTransaction(db, async (transaction) => {
        // Update banner in banners collection
        transaction.update(bannerDocRef, {
          ...bannerInfo,
          updatedAt: Timestamp.now(),
        })
        // Update banner in company home
        // If not default, delete banner from company home
        if (!bannerInfo?.isDefault) {
          transaction.delete(companyHomeDocRef)
        } else {
          // If default, merge existing banner data with new data and update company home
          let mergedBannerData = { ...bannerExistData, ...bannerDataForHome };
          // Set new data to company home
          transaction.set(companyHomeDocRef, {
            type: 'BANNER',
            data: {
              ...mergedBannerData,
              status: bannerDataForHome.status,
              id: `BANNER_${bannerInfo.id}`,
            },
          })
        }

        // Update banner in all store home documents
        storeDocs.docs.forEach((storeDoc) => {
          const storeId = storeDoc.id
          const storeHomeDocRef = doc(
            db,
            `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.STORES}/${storeId}/${COLLECTIONS.HOME}`,
            `BANNER_${bannerInfo.id}`
          )
          transaction.update(storeHomeDocRef, bannerData)
        })
      })
    } else {

      // Add banner to banners collection
      const bannerDocRef = doc(collection(db, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.BANNERS}`))
      batch.set(bannerDocRef, {
        ...bannerInfo,
        id: bannerDocRef.id,
        createdAt: Timestamp.now(),
      })

      const bannerId = `BANNER_${bannerDocRef.id}`

      // Add banner to company home
      if (bannerInfo?.isDefault) {
        const companyHomeDocRef = doc(companyHomeRef, bannerId)
        batch.set(companyHomeDocRef, {
          type: 'BANNER',
          data: {
            ...bannerInfo,
            id: bannerId,
            createdAt: Timestamp.now(),
          },
        })
      }
      // Add banner to all stores home
      storeDocs.docs.forEach((storeDoc) => {
        const storeId = storeDoc.id
        const storeHomeDocRef = doc(
          db,
          `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.STORES}/${storeId}/${COLLECTIONS.HOME}`,
          bannerId
        )
        batch.set(storeHomeDocRef, {
          type: 'BANNER',
          data: {
            ...bannerInfo,
            id: bannerId,
            createdAt: Timestamp.now(),
          },
        })
      })

      await batch.commit()
    }
    return true
  } catch (error) {
    logger.error('Error while adding/updating banner:', error)
    return null
  }
}
