import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Toggle from '@common/src/common/Toggle/Toggle';
import InputBox from '@common/src/common/inputbox/inputbox';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_STORE_PRODUCTS, GET_PRODUCT_DETAILS } from '@common/src/lib/graphql/query/product';
import { useToast } from '@common/src/common/Toast/ToastProvider';
import { useSelector } from 'react-redux';
import { assignProduct } from '@common/src/service/cloud/catalog';
import { useDebounce } from '@common/src/common/hook/useDebounce';

const LocationTable = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedIds, setSelectedIds] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // Debounce with a 500ms delay
  const [productDetails, setProductDetails] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [allStoreData, setAllStoreData] = useState([]); // Global state for all pages
  const [deselectedIds, setDeselectedIds] = useState([]);
  const { selectedCompany } = useSelector((state) => state.auth);
  const queryParams = new URLSearchParams(location.search);
  const { state } = useLocation();
  const productId = queryParams.get('productId');
  const [pagination, setPagination] = useState({
    limit: 25,
    offset: 0,
    currentPage: 1,
  });

  const { data, refetch, loading } = useQuery(GET_COMPANY_STORE_PRODUCTS, {
    variables: {
      companyId: selectedCompany?.value,
      productId: parseInt(productId),
      search: debouncedSearchTerm,
      limit: pagination.limit,
      offset: pagination.offset,
    },
    fetchPolicy: "network-only",
  });

  const { data: productData } = useQuery(GET_PRODUCT_DETAILS, {
    variables: { id: parseInt(productId) }, // Ensure id is an integer
    skip: !productId, // Skip the query if no id is present
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (productData) {
      setProductDetails(productData.adminProductDetailsQuery);
    }
  }, [productData]);

  useEffect(() => {
    if (data) {
      setStoreData(data.companyStoreProductsQuery.rows);

      // Merge new data with existing allStoreData
      const newData = data.companyStoreProductsQuery.rows.filter(
        (newStore) => !allStoreData.some((store) => store.id === newStore.id)
      );
      setAllStoreData([...allStoreData, ...newData]);

      // Merge new selectedIds with existing ones
      const newSelectedIds = data.companyStoreProductsQuery.rows
        .filter((store) => store?.products?.isAvailable !== null)
        .map((store) => store.id);
      setSelectedIds((prevSelectedIds) => [...new Set([...prevSelectedIds, ...newSelectedIds])]);

      // Merge new input values with existing ones
      const newInputValues = {};
      data.companyStoreProductsQuery.rows.forEach((store) => {
        newInputValues[store.id] = {
          DELIVERY: (inputValues[store.id]?.DELIVERY ?? store?.products?.prices?.DELIVERY) || defaultSalesPrice,
          TAKEAWAY: (inputValues[store.id]?.TAKEAWAY ?? store?.products?.prices?.TAKEAWAY) || defaultSalesPrice,
          GLOBAL_DELIVERY: (inputValues[store.id]?.GLOBAL_DELIVERY ?? store?.products?.prices?.GLOBAL_DELIVERY) || defaultSalesPrice,
          GLOBAL_TAKEAWAY: (inputValues[store.id]?.GLOBAL_TAKEAWAY ?? store?.products?.prices?.GLOBAL_TAKEAWAY) || defaultSalesPrice,
        };
      });
      setInputValues((prevInputValues) => ({ ...prevInputValues, ...newInputValues }));
    }
  }, [data, productDetails]);

  useEffect(() => {
    refetch({
      companyId: selectedCompany?.value,
      productId: parseInt(productId),
      search: debouncedSearchTerm,
      limit: pagination.limit,
      offset: pagination.offset,
    });
  }, [pagination.offset, debouncedSearchTerm]);

  const handleNext = () => {
    if (data.companyStoreProductsQuery.rows.length < pagination.limit) {
      return;
    }
    setPagination((prevPagination) => ({
      ...prevPagination,
      offset: prevPagination.offset + prevPagination.limit,
      currentPage: prevPagination.currentPage + 1,
    }));
  };

  const handlePrevious = () => {
    if (pagination.offset <= 0) {
      return;
    }
    setPagination((prevPagination) => ({
      ...prevPagination,
      offset: prevPagination.offset - prevPagination.limit,
      currentPage: prevPagination.currentPage - 1,
    }));
  };

  const handleInputChange = (storeId, priceType, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [storeId]: {
        ...prevValues[storeId],
        [priceType]: value,
      },
    }));

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    // Combine allStoreData with selectedIds and inputValues
    const combinedData = allStoreData
      .filter((store) => selectedIds.includes(store.id) && !deselectedIds.includes(store.id)) // Exclude deselected items
      .map((store) => {
        const existingPrices = store?.products?.prices || {};
        const newPrices = inputValues[store.id] || {};
        return {
          storeId: store.id,
          prices: {
            DELIVERY: newPrices.DELIVERY !== undefined ? parseFloat(newPrices.DELIVERY) : parseFloat(existingPrices.DELIVERY) || defaultSalesPrice,
            TAKEAWAY: newPrices.TAKEAWAY !== undefined ? parseFloat(newPrices.TAKEAWAY) : parseFloat(existingPrices.TAKEAWAY) || defaultSalesPrice,
            GLOBAL_DELIVERY: newPrices.GLOBAL_DELIVERY !== undefined ? parseFloat(newPrices.GLOBAL_DELIVERY) : parseFloat(existingPrices.GLOBAL_DELIVERY) || defaultSalesPrice,
            GLOBAL_TAKEAWAY: newPrices.GLOBAL_TAKEAWAY !== undefined ? parseFloat(newPrices.GLOBAL_TAKEAWAY) : parseFloat(existingPrices.GLOBAL_TAKEAWAY) || defaultSalesPrice,
          },
        };
      });

    const submittedData = {
      productId: parseInt(productId),
      stores: combinedData,
    };

    try {
      const response = await assignProduct(submittedData);
      if (response) {
        toast.success("Product updated to store successfully");
        // Refetch the query to update the UI
        await refetch();
        const path = state?.redirectUrl || `${import.meta.env.BASE_URL}menu-management`;
        navigate(path);
      }
    } catch {
      toast.error("Failed to assign product");
    } finally {
      setIsLoading(false); // End loading
    }
  };

  // Toggle individual item
  const handleToggleChange = (id, value) => {
    if (value) {
      // If toggled on, add to selectedIds and remove from deselectedIds
      setSelectedIds((prevIds) => [...new Set([...prevIds, id])]);
      setDeselectedIds((prevIds) => prevIds.filter((itemId) => itemId !== id));
    } else {
      // If toggled off, remove from selectedIds and add to deselectedIds
      setSelectedIds((prevIds) => prevIds.filter((itemId) => itemId !== id));
      setDeselectedIds((prevIds) => [...new Set([...prevIds, id])]);
      // Reset input values to default price for the toggled-off store
      setInputValues((prevValues) => ({
        ...prevValues,
        [id]: {
          DELIVERY: defaultSalesPrice,
          TAKEAWAY: defaultSalesPrice,
          GLOBAL_DELIVERY: defaultSalesPrice,
          GLOBAL_TAKEAWAY: defaultSalesPrice,
        },
      }));
    }
  };
  // Toggle all items
  const handleToggleAll = () => {
    const currentPageIds = storeData.map((item) => item.id);

    if (isAllSelected) {
      // Deselect all items on the current page
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => !currentPageIds.includes(id))
      );
      setDeselectedIds((prevDeselectedIds) => [...new Set([...prevDeselectedIds, ...currentPageIds])]);
      // Reset input values to default price for all deselected stores
      const resetValues = currentPageIds.reduce((acc, id) => {
        acc[id] = {
          DELIVERY: defaultSalesPrice,
          TAKEAWAY: defaultSalesPrice,
          GLOBAL_DELIVERY: defaultSalesPrice,
          GLOBAL_TAKEAWAY: defaultSalesPrice,
        };
        return acc;
      }, {});
      setInputValues((prevValues) => ({
        ...prevValues,
        ...resetValues,
      }));

    } else {
      // Select all items on the current page
      setSelectedIds((prevSelectedIds) => [...new Set([...prevSelectedIds, ...currentPageIds])]);
      setDeselectedIds((prevDeselectedIds) =>
        prevDeselectedIds.filter((id) => !currentPageIds.includes(id))
      );
    }
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      offset: 0, // Reset offset when a new search is initiated
      currentPage: 1,
    }));
  };
  const services = productDetails?.services || [];
  const defaultSalesPrice = productDetails?.defaultSalesPrice || 0;
  const hasStoreData = storeData.length > 0;
  const isAllSelected = storeData.every((item) => selectedIds.includes(item.id) && !deselectedIds.includes(item.id));
  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-12 gap-6">
        <div className="xl:col-span-12 col-span-12">
          <div className="box">
            <div className="box-header">
              <div className="box-title">{productDetails?.name?.en} - Price and Availability in stores</div>
              <InputBox
                type="text"
                placeholder="Search by store name / location"
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
                style={{ width: '225px' }}
              />
            </div>
            <div className="box-body">
              <div className="table-responsive mb-4">
                <table className="table whitespace-nowrap table-bordered min-w-full">
                  <thead>
                    <tr>
                      <th scope="col" className="text-start">
                       Store Name
                      </th>
                      <th scope="col" className="text-start">
                       Store Location
                      </th>
                      <th scope="col" className="text-start">
                        Availability{' '}
                        <button
                          onClick={handleToggleAll}
                          type="button"
                          className="text-[0.75rem] font-semibold rounded-md bg-primary/10 px-2 py-1 hover:bg-primary/20 transition-colors"
                        >
                          {isAllSelected ? "Deselect All" : "Select All"}
                        </button>
                      </th>
                      {services.includes("DELIVERY") && (
                        <th scope="col" className="text-start">
                          Delivery Price
                        </th>
                      )}
                      {services.includes("TAKEAWAY") && (
                        <th scope="col" className="text-start">
                          Takeaway Price
                        </th>
                      )}
                      {services.includes("GLOBAL_DELIVERY") && (
                        <th scope="col" className="text-start">
                          Global Delivery Price
                        </th>
                      )}
                      {services.includes("GLOBAL_TAKEAWAY") && (
                        <th scope="col" className="text-start">
                          Global Takeaway Price
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {storeData.map((item) => {
                      const isSelected = selectedIds.includes(item.id) && !deselectedIds.includes(item.id);
                      return (
                        <tr className="product-list" key={item.id}>
                          <td>
                            <div className="flex items-center">
                              <div className="me-2">
                                <span className="avatar avatar-md avatar-rounded">
                                  <img src={item.photo} alt={item.name.en} />
                                </span>
                              </div>
                              <div className="font-semibold">{item.name.en}</div>
                            </div>
                          </td>
                          <td>
                            <div className="font-semibold">{item?.address?.city?.en || item.name.en}</div>
                          </td>
                          <td>
                            <Toggle
                              label="Availability"
                              value={isSelected}
                              onChange={(e) => handleToggleChange(item.id, e.target.checked)}
                            />
                          </td>
                          {services.includes("DELIVERY") && (
                            <td>
                              <InputBox
                                placeholder="Delivery Price"
                                name="deliveryPrice"
                                value={inputValues[item.id]?.DELIVERY ?? defaultSalesPrice}
                                onChange={(e) => handleInputChange(item.id, "DELIVERY", e.target.value)}
                                disabled={!isSelected}
                              />
                            </td>
                          )}
                          {services.includes("TAKEAWAY") && (
                            <td>
                              <InputBox
                                placeholder="Takeaway Price"
                                name="takeawayPrice"
                                value={inputValues[item.id]?.TAKEAWAY ?? defaultSalesPrice}
                                onChange={(e) => handleInputChange(item.id, "TAKEAWAY", e.target.value)}
                                disabled={!isSelected}
                              />
                            </td>
                          )}
                          {services.includes("GLOBAL_DELIVERY") && (
                            <td>
                              <InputBox
                                placeholder="Global Delivery Price"
                                name="globalDeliveryPrice"
                                value={inputValues[item.id]?.GLOBAL_DELIVERY ?? defaultSalesPrice}
                                onChange={(e) => handleInputChange(item.id, "GLOBAL_DELIVERY", e.target.value)}
                                disabled={!isSelected}
                              />
                            </td>
                          )}
                          {services.includes("GLOBAL_TAKEAWAY") && (
                            <td>
                              <InputBox
                                placeholder="Global Takeaway Price"
                                name="globalTakeawayPrice"
                                value={inputValues[item.id]?.GLOBAL_TAKEAWAY ?? defaultSalesPrice}
                                onChange={(e) => handleInputChange(item.id, "GLOBAL_TAKEAWAY", e.target.value)}
                                disabled={!isSelected}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}

                    {storeData.length === 0 && (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No stores found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="sm:flex items-center justify-between flex-wrap mb-4">
                <div>
                  {hasStoreData ? (
                    <span>
                      Showing {pagination.offset + 1} -{' '}
                      {Math.min(pagination.offset + pagination.limit, storeData.length)} of {storeData.length} stores
                    </span>
                  ) : (
                    <span>Total: {storeData.length} stores</span>
                  )}
                </div>
              </div>
              <div className="sm:flex items-center justify-between flex-wrap mb-4">
                <nav aria-label="Page navigation">
                  <ul className="ti-pagination mb-0 flex flex-row rounded-sm text-[1rem] !ps-0">
                    <li className="page-item disabled">
                      <button className="page-link !py-[0.375rem] !px-[0.75rem]" type='button' onClick={handlePrevious}>
                        Previous
                      </button>
                    </li>
                    <li className="page-item active">
                      <button className="page-link !py-[0.375rem] !px-[0.75rem]" type='button'  to="#">
                        {pagination.currentPage}
                      </button>
                    </li>
                    <li className="page-item">
                      <button className="page-link !py-[0.375rem] !px-[0.75rem]" type='button' onClick={handleNext}>
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
              {/* Submit Button */}
              <div className="flex justify-end">
                <button type="submit" className="ti-btn ti-btn-primary !font-medium m-1" disabled={loading}>
                  {isLoading ? (
                    <>
                      <span className="me-2">Loading</span>
                      <span className="loading">
                        <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
                      </span>
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LocationTable;