import React, { useEffect, useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import * as Yup from 'yup'
import AddProductButton from '../../../components/common/buttons/AddProductButton'
import PropTypes from 'prop-types'
import { getCategoryById } from '@common/src/service/firebase/categories'
import { useSelector } from 'react-redux'
import { CATEGORY_OPTIONS } from '@hub/constants/constants'
import { transformToValueLabel } from '@common/src/common/helper'
import { handleAsyncBackOperation } from '../helper'

const validationSchema = Yup.object().shape({
  sortOrder: Yup.number()
    .typeError('Sort order must be a number')
    .min(1, 'Sort order must be greater than zero')
    .required('Sort order is required'),
  defaultSalesPrice: Yup.number()
    .typeError('Default price must be a number')
    .min(1, 'Default price must be greater than zero')
    .required('Default price is required'),
  service: Yup.array()
    .min(1, 'At least one transaction type is required')
    .required('Transaction type is required'),
})
const Pricing = ({ handleNext, handleBack, setProductData, productData, isEdit, setEditProductData, editProductData, setDisableNavigation }) => {

  const { selectedCompany } = useSelector((state) => state.auth);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [isBackLoading, setIsBackLoading] = useState(false);


  const handleSubmit = async (values) => {
    setIsLoading(true)
    setDisableNavigation(true)
    await submitLogic(values)
    setTimeout(() => {
      setIsLoading(false)
      setDisableNavigation(false)
      handleNext()
    }, 1000)
  }

  const submitLogic = async (values) => {
    if (isEdit) {
      const updatedEditProductData = {
        ...editProductData,
        sortOrder: values.sortOrder,
        defaultSalesPrice: values.defaultSalesPrice,
        marketPrice: values.marketPrice || 0,
        services: values.service.map((service) => service.value),
      };
      setEditProductData(updatedEditProductData);



      // Use the updated values directly to set productData
      setProductData((prevData) => ({
        ...prevData,
        ...updatedEditProductData,
      }));
    } else {
      setProductData({
        ...productData,
        sortOrder: values.sortOrder,
        defaultSalesPrice: values.defaultSalesPrice,
        marketPrice: values.marketPrice,
        services: values.service.map((service) => service.value),
      });
    }
  };
  useEffect(() => {
    const fetchServices = async () => {

      try {
        const categoryData = await getCategoryById(selectedCompany?.value, productData.categoryId);
        if (categoryData) {
          // Transform services using the predefined options  
          const formattedOptions = transformToValueLabel(categoryData.services, CATEGORY_OPTIONS);
          setOptions(formattedOptions);
        } else {
          console.error('No services found for the category.');
        }
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, [productData?.categoryId, selectedCompany?.value]);

  const handleBackWithLoader = async () => {
    handleAsyncBackOperation(handleBack, setIsBackLoading, setDisableNavigation);
  };

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="xl:col-span-12 col-span-12">
        <div className="box">
          <div className="box-body add-products !p-0">
            <div className="p-6">
              <Formik
                initialValues={{
                  sortOrder: productData?.sortOrder || 0,
                  defaultSalesPrice: productData?.defaultSalesPrice || 0,
                  marketPrice: productData?.marketPrice || null,
                  service: productData?.services ? transformToValueLabel(productData.services, CATEGORY_OPTIONS) : [],
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmit(values)
                }}
              >
                {({ setFieldValue, errors, touched, values }) => (
                  <Form>
                    <div className="grid grid-cols-2 gap-6">
                      <div className="xl:col-span-1 col-span-12">
                        <InputBox
                          label="Sort Order"
                          name="sortOrder"
                          type="number"
                          placeholder="Sort Order"
                          value={values.sortOrder}
                          onChange={(e) => setFieldValue('sortOrder', parseInt(e.target.value))}
                          error={errors.sortOrder && touched.sortOrder ? errors.sortOrder : null}
                          required
                        />
                        <ErrorMessage name="sortOrder" component="div" style={{ color: 'red' }} />
                      </div>
                      <div className="xl:col-span-1 col-span-12">
                        <InputBox
                          label="Default Price"
                          name="defaultSalesPrice"
                          type="number"
                          placeholder="Default Price"
                          value={values.defaultSalesPrice}
                          onChange={(e) => setFieldValue('defaultSalesPrice', parseFloat(e.target.value))}
                          error={
                            errors.defaultSalesPrice && touched.defaultSalesPrice ? errors.defaultSalesPrice : null
                          }
                          required
                        />
                        <ErrorMessage name="defaultSalesPrice" component="div" style={{ color: 'red' }} />
                      </div>
                      <div className="xl:col-span-1 col-span-12">
                        <InputBox
                          label="Markup Price"
                          name="marketPrice"
                          type="number"
                          placeholder="Markup Price"
                          value={values.marketPrice || ""}
                          onChange={(e) => setFieldValue('marketPrice', parseFloat(e.target.value))}
                        />
                        <ErrorMessage name="marketPrice" component="div" style={{ color: 'red' }} />
                      </div>
                      <div className="xl:col-span-1 col-span-12">
                        <SelectBox
                          label="Transaction Type"
                          name="service"
                          options={options}
                          isMulti={true}
                          value={values.service.map((item) => ({
                            label: item.label,
                            value: item.value,
                            selected: true,
                          }))}
                          placeholder={options.length > 0 ? 'Select transaction type' : 'Please select category'}
                          onChange={(e) => setFieldValue('service', e)}
                          error={errors.service && touched.service ? errors.service : null}
                          required
                        />
                        <ErrorMessage name="service" component="div" style={{ color: 'red' }} />
                      </div>
                    </div>
                    <AddProductButton
                      handleBack={handleBackWithLoader}
                      handleNext={() => handleSubmit(values)}
                      loadingBack={isBackLoading}
                      loadingNext={isLoading}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Pricing.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  setProductData: PropTypes.func.isRequired,
  productData: PropTypes.array,
  isEdit: PropTypes.bool,
  editProductData: PropTypes.object,
  setEditProductData: PropTypes.func,
  setDisableNavigation: PropTypes.func
}

export default Pricing
