import { gql } from '@apollo/client'

export const GET_ALL_ORDERS = gql`
  query getAllOrders($search: String, $limit: Int, $offset: Int, $filters: OrderFiltersInput) {
     orderListQuery(search: $search, limit: $limit, offset: $offset, filters: $filters) {
      limit
      offset
      totalPages
        rows{
            orderId
            orderNumber
            customer{
                firstName
                lastName
                phoneNumber
            }
            address{
             phone
            }
            orderDate
            service
            total
            paymentMode
            paymentStatus
            orderStatus
            store{
                outletCode
                address{
                    city{
                      en
                      ar
                    }
                }
            }
            meta{
                config{
                    currency{
                        currency
                        currencyCode
                    }
                    country{
                      timezone
                    }
                }
            }
        }
    }
  }
`;



export const GET_ORDER_DETAILS = gql`
    query getOrder($id: String) {
        orderQuery(id: $id) {
           id
    service
    orderId
    orderToken
    orderNumber
    createdAt
    outletCode
    expectText {
      ar
      en
    }
    store {
      name {
        ar
        en
      }
      address {
        address {
          ar
          en
        }
      }
    }
    address {
      name
      phoneCode
      phone
      houseNumber
      apartment
      direction
      addressType
      formatted
      geoData {
        coordinates {
          lat
          lng
        }
      }
    }
    user {
      id
      firstName
      firstName
      email
      phoneCode
      phoneNumber
    }
    meta {
      config {
        currency {
          currencyCode
        }
        country{
          timezone
        }
        biteCoinConfig {
          coinValue
          minCoin
        }
        taxConfig {
          gst
          vat
          taxType
          isInclusive
          tin
        }
      }
    }
    orderStatus
    paymentStatus
    lang
    remarks
    loyaltyPoints {
      earnedCoins {
        coin
        value
      }
      usedCoins {
        coin
        value
      }
    }
    count
    subtotal
    deliveryCharge
    discountedPrice
    total
    taxTotal
    itemTotal
    paymentMode
    customerCareNumber
    products {
      id
      itemId
      remark
      product {
        name {
          ar
          en
        }
        image
        defaultSalesPrice
        price
        storesPrice {
          prices {
            DELIVERY
            TAKEAWAY
            GLOBAL_DELIVERY
            GLOBAL_TAKEAWAY
          }
        }
        description {
          ar
          en
        }
      }
      remark
      quantity
      unitPrice
      amount
      modifierGroups {
        id
        groupName {
          ar
          en
        }
        type
        modifiers {
          id
          quantity
          unitPrice
          amount
          modifier {
            modItemName {
              en
              ar
            }
            image
            foodType
          }
        }
      }
    }
    }
}`;