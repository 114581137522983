import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AddProductButton from '../../../components/common/buttons/AddProductButton';
import Toggle from '@common/src/common/Toggle/Toggle';
import { createProduct, updateProduct } from '@common/src/service/cloud/catalog';
import { useToast } from '@common/src/common/Toast/ToastProvider';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { STATUS_OPTIONS } from '@hub/constants/constants';
import { handleAsyncBackOperation } from '../helper';

const Configuration = ({ productData, setProductData, redirectUrl, isEdit, editProductData, setEditProductData, completedSteps, handleBack, setDisableNavigation }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isBackLoading, setIsBackLoading] = useState(false);
  const [error, setError] = useState(null);
  const { selectedCompany } = useSelector((state) => state.auth);

  const validationSchema = Yup.object().shape({
    default: Yup.boolean(),
    isRecommend: Yup.boolean(),
  });
  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("completedSteps", completedSteps);
    // Check only the first six steps
    if (!isEdit && !completedSteps.slice(0, 6).every((step) => step)) {
      setError('Please complete all steps before submitting.');
      setSubmitting(false);
      return;
    }

    setIsLoading(true);
    setDisableNavigation(true);
    let updatedData = {
      default: values.default,
      isRecommend: values.isRecommend,
      status: STATUS_OPTIONS[0].value,
      url: "",
      isDeleted: false,
      isAvailableInStore: true,
      companyId: selectedCompany?.value,
      timeZone: selectedCompany?.timeZone,
      ...isEdit ? editProductData : productData,
    };
    if (isEdit) {
      setEditProductData({ updatedData });
    } else {
      setProductData({ updatedData });
    }

    try {
      let response;
      if (isEdit) {
        response = await updateProduct(updatedData);
      } else {
        response = await createProduct(updatedData);
      }

      if (response) {
        toast.success(`Product ${isEdit ? 'updated' : 'created'} successfully`);
        const path = isEdit ? redirectUrl : `${import.meta.env.BASE_URL}menu-management`;
        navigate(path);
      }
    } catch (error) {
      console.error(`Error ${isEdit ? 'updating' : 'creating'} product:`, error.message);
      setProductData({...productData, ...editProductData})
      toast.error('Please complete all steps and ensure you have a valid POS ID before submitting.');
    } finally {
      setSubmitting(false);
      setIsLoading(false);
      setDisableNavigation(false);
    }
  };

  const handleBackWithLoader = async () => {
    handleAsyncBackOperation(handleBack, setIsBackLoading, setDisableNavigation);
  };


  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="xl:col-span-12 col-span-12">
        <div className="box">
          <div className="box-body add-products !p-0">
            <div className="p-6">
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <Formik
                initialValues={{
                  default: productData?.default || false,
                  isRecommend: productData?.isRecommend || false,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue, errors, touched, values }) => (
                  <Form>
                    <div className="grid grid-cols-2 gap-6">
                      <Toggle
                        label="Is Default"
                        value={values.default}
                        onChange={(e) => setFieldValue('default', e.target.checked)}
                        disabled ={isLoading}
                      />
                      <Toggle
                        label="Is Recommended"
                        value={values.isRecommend}
                        onChange={(e) => setFieldValue('isRecommend', e.target.checked)}
                        disabled={isLoading}
                      />
                    </div>
                    <AddProductButton handleBack={handleBackWithLoader} loadingBack={isBackLoading} 
                      loadingNext={isLoading} buttonText="Submit" />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Configuration.propTypes = {
  productData: PropTypes.object,
  setProductData: PropTypes.func,
  isEdit: PropTypes.bool,
  editProductData: PropTypes.object,
  setEditProductData: PropTypes.func,
  completedSteps: PropTypes.array.isRequired,
  handleBack: PropTypes.func,
  redirectUrl: PropTypes.string,
  setDisableNavigation: PropTypes.func
};

export default Configuration;