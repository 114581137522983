export const handleAsyncBackOperation = async (operation, setLoading, setDisableNavigation, delay = 1000) => {
    setLoading(true);
    setDisableNavigation(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, delay)); // Simulated delay
      await operation(); // Execute the passed operation
    } catch (error) {
      console.error('Error during operation:', error);
    } finally {
      setLoading(false);
      setDisableNavigation(false);
    }
  };