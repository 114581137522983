import { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import CropImage from '@common/src/common/ImageUpload/CropImage'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'
import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput'
import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import { storeAction } from '@common/src/service/cloud/catalog'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { SERVICE_OPTIONS, PAYMENT_OPTIONS, STATUS_OPTIONS, IMG_UPLOAD, DEFAULT_OPTIONS_STORE } from '@hub/constants/constants'
import { getCompanies } from '../../../shared/src/service/firebase/api'
import PreviewImage from '@hub/components/common/ImageHolders/previewImage'
import { addOrKeepSeconds } from '@common/src/common/helper'


const validationSchema = Yup.object({
  name: Yup.object({
    en: Yup.string().required('English name is required'),
    ar: Yup.string().required('Arabic name is required'),
  }).required('Name is required'),
  email: Yup.string().email('Invalid email address').optional(),
  companyId: Yup.string().required('Company is required'),
  outletCode: Yup.string().max(10, 'The maximum character count for the outlet code is 10.').required('Outlet Code is required'),
  phoneNumber: Yup.string()
    .matches(/^\d{1,14}$/, 'Phone number must contain less than 15 digits').required('Phone number is required'),
  address: Yup.object({
    en: Yup.string().required('Address in English is required'),
    ar: Yup.string().required('Address in Arabic name is required'),
  }).required('Name is required'),
  latitude: Yup.number()
    .required('Latitude is required')
    .min(-90, 'Latitude must be between -90 and 90')
    .max(90, 'Latitude must be between -90 and 90'),
  longitude: Yup.number()
    .required('Longitude is required')
    .min(-180, 'Longitude must be between -180 and 180')
    .max(180, 'Longitude must be between -180 and 180'),
  storeLocation: Yup.object({
    en: Yup.string().required('Location in English is required'),
    ar: Yup.string().optional(),
  }).required('Name is required'),
  open: Yup.string().required('Open time is required'),
  close: Yup.string()
    .required('Close time is required')
    .test('close-not-equal-open', 'Open time and close time cannot be same', function (value) {
      return addOrKeepSeconds(this.parent.open) !== addOrKeepSeconds(value)
    }),
  isClosed: Yup.boolean().required('Store closed status required'),
  services: Yup.array().min(1, 'At least one service must be selected').required('At least one service must be selected'),
  paymentTypes: Yup.array().min(1, 'At least one payment type must be selected').required('At least one payment type must be selected'),
  config: Yup.object().shape({
    deliveryConfig: Yup.object().shape({
      minCartValue: Yup.number()
        .required('Minimum cart value is required')
        .min(0, 'Minimum cart value should be at least 0'),
      minDeliveryCost: Yup.number()
        .required('Minimum delivery cost is required')
        .min(0, 'Minimum delivery cost should be at least 0'),
      minDeliveryForKm: Yup.number()
        .required('Minimum delivery distance is required')
        .min(0, 'Minimum delivery distance should be at least 0 km'),
      costPerKm: Yup.number().required('Cost per km is required'),
      maxDeliveryDistance: Yup.number()
        .required('Maximum delivery distance is required')
        .min(1, 'Maximum delivery distance should be at least 0 km')
        .test('maxDeliveryDistance-decimal', 'Maximum delivery distance can have up to 2 decimal places', (value) =>
          /^\d+(\.\d{1,2})?$/.test(value)
        ),
    }),
  }),
  imageUrl: Yup.string().required('Image is required'),
})

const StoreForm = ({ id, data, isEdit, onComplete }) => {
  const toast = useToast()
  const cropImageRef = useRef(null)
  const [isImage, setIsImage] = useState(!!data?.photo);
  const [phoneCode, setPhoneCode] = useState('+91')
  const [companyOptions, setCompanyOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    companyId: '',
    name: { en: '', ar: '' },
    email: '',
    status: '',
    phoneNumber: '',
    countryCode: '',
    outletCode: '',
    latitude: '',
    longitude: '',
    services: [],
    address: { en: '', ar: '' },
    storeLocation: '',
    paymentTypes: [],
    open: '',
    close: '',
    isClosed: false,
    config: {
      deliveryConfig: {
        minCartValue: '',
        minDeliveryCost: '',
        minDeliveryForKm: '',
        costPerKm: '',
        maxDeliveryDistance: '',
      },
    },
    imageUrl: '',
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [companies] = await Promise.all([getCompanies()])
        setCompanyOptions(companies)
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    }



    const getFilteredServices = (services) =>
      SERVICE_OPTIONS.filter((item) => (services || []).includes(item.value));
    const getPaymenTypesArr = (types) =>
      PAYMENT_OPTIONS.filter((item) => (types || []).includes(item.value));

    const initializeValues = (data) => {
      if (!data) return;

      const { services, paymentTypes, storeContacts, config } = data;
      let formatted = {
        deliveryConfig: config?.deliveryConfig || {},
        filteredServices: getFilteredServices(services || []),
        paymentTypesArr: getPaymenTypesArr(paymentTypes || []),
      }
      setPhoneCode(storeContacts?.phoneCode || '+91');
      prepareInitialData(data, formatted)
    };
    const prepareInitialData = (data, { deliveryConfig, filteredServices, paymentTypesArr }) => {
      const preparedData = {
        companyId: parseInt(data?.company?.id || '', 10),
        name: data?.name || { en: '', ar: '' },
        email: data?.storeContacts?.email || '',
        status: data?.status || 'ACTIVE',
        phoneNumber: data?.storeContacts?.phone || '',
        outletCode: data?.outletCode || '',
        address: data?.address?.address || { en: '', ar: '' },
        storeLocation: data?.address?.city || '',
        services: filteredServices,
        paymentTypes: paymentTypesArr,
        open: data?.open || '',
        close: data?.close || '',
        isClosed: data?.isClosed,
        config: {
          deliveryConfig: {
            ...deliveryConfig,
          },
        },
        imageUrl: data?.photo,
      };

      if (data?.location?.coordinates) {
        preparedData.latitude = data.location.coordinates[1];
        preparedData.longitude = data.location.coordinates[0];
      }

      setInitialValues(preparedData);
    };

    fetchData()
    initializeValues(data);
  }, [data])
  const onUploadError = (error) => {
    toast.error('Error uploading image')
    onComplete?.()
  }

  const handleSubmit = async (values, actions) => {
    setIsLoading(true)
    if (cropImageRef.current) {
      cropImageRef.current.uploadImage()
    } else {
      await onUploadSuccess({ values, actions })
    }
  }
  const onUploadSuccess = async ({ values, actions, url = '' }) => {
    actions?.setSubmitting(true)
    setIsLoading(true)
    const selectedValues = values.services ? values.services.map((option) => option.value) : []
    const selectedPaymentTypes = values.paymentTypes ? values.paymentTypes.map((option) => option.value) : []

    const formData = {
      companyId: parseInt(values.companyId, 10), // Ensure integer type
      name: {
        en: values.name.en,
        ar: values.name.ar,
      },
      storeContacts: {
        email: values.email,
        phone: values.phoneNumber,
        phoneCode: phoneCode,
      },
      photo: url || values.imageUrl,
      address: {
        street: {
          en: "",
          ar: "",
        },
        city: {
          en: values?.storeLocation.en,
          ar: values?.storeLocation?.ar || "",
        },
        address: {
          en: values.address.en,
          ar: values.address.ar,
        },
        nationality: {
          en: "",
          ar: "",
        },
      },
      location: `${parseFloat(values?.latitude)},${parseFloat(values?.longitude)}`,
      countryCode: values.countryCode,
      // Remove any whitespace from the outlet code
      outletCode: values.outletCode.replace(/\s+/g, ''),
      timeZone: values.timezone,
      services: selectedValues,
      paymentTypes: selectedPaymentTypes,
      open: values.open,
      close: values.close,
      isClosed: values.isClosed,
      currency: values.currency,
      maxDeliveryDistance: parseFloat(values.config.deliveryConfig.maxDeliveryDistance),
      promise: 20, // Integer constant as specified
      config: {
        deliveryConfig: {
          minCartValue: parseFloat(values.config.deliveryConfig.minCartValue),
          minDeliveryCost: parseFloat(values.config.deliveryConfig.minDeliveryCost),
          minDeliveryForKm: parseFloat(values.config.deliveryConfig.minDeliveryForKm),
          costPerKm: parseFloat(values.config.deliveryConfig.costPerKm),
          maxDeliveryDistance: parseFloat(values.config.deliveryConfig.maxDeliveryDistance),
        },
      },
      status: data?.status || STATUS_OPTIONS[0].value,
      id: data?.id ? parseInt(data.id, 10) : null, // Cast id to integer if it exists
    }
    try {
      await storeAction(formData, isEdit)
      const toastMessage = isEdit ? 'Store updated successfully!' : 'Store added successfully!'
      toast.success(toastMessage)
      actions?.resetForm()
      onComplete?.()
    } catch (error) {
      if (error.message === 'outlet_code must be unique') {
        toast.error('Outlet code must be unique')
      } else {
        toast.error(isEdit ? 'Error updating Store.' : 'Error creating Store.')
      }
      console.error('Form Submission Error:', error)
    }
    finally {
      setIsLoading(false)
    }
  }


  const handleInputChange = (fieldName, setFieldValue) => (e) => {
    const sanitizedValue = e.target.value.replace(/^\s+/, ''); // Remove leading spaces
    setFieldValue(fieldName, sanitizedValue);
  };

  const handleSelectChange = (fieldName, setFieldValue) => (e) => {
    setFieldValue(fieldName, e);
  };

  return (
    <OffCanvasForm
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      okText={isEdit ? 'Update' : 'Add'}
      closeText="Cancel"
      id={id}
      autoClose={false}
      loading={isLoading}
    >
      {({ setFieldValue, values, errors, touched }) => {
        const renderInput = (label, name, type = 'text', prefixValue = undefined, required = false) => {

          return (
            <InputBox
              label={label}
              name={name}
              type={type}
              value={values[name]}
              onChange={handleInputChange(name, setFieldValue)}
              errorText={errors[name] && touched[name] ? errors[name] : null}
              prefixValue={prefixValue}
              required={required}
            />
          )
        };

        const renderSelect = (label, name, isMulti = false, options = [], disabled = false, required = false) => {

          return (
            <SelectBox
              label={label}
              name={name}
              isMulti={isMulti}
              options={options}
              disabled={disabled}
              value={values[name]}
              onChange={handleSelectChange(name, setFieldValue)}
              errorText={errors[name] && touched[name] ? errors[name] : null}
              required={required}
              isClearable={false}
            />
          )
        };

        const renderFormRow = (components, col = 2) => (
          <div className={`mt-6 grid grid-cols-${col} gap-6`}>{components}</div>
        );

        const renderMultiLanguageInput = (label, name, required = false) => (
          <MultiLanguageInput
            type="input"
            label={label}
            id={`${name}-add`}
            name={name}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            values={values}
            required={required}
          />
        );

        const renderCropImage = () => (
          <CropImage
            value={values.imageUrl}
            onChange={(file) => setFieldValue('imageUrl', file)}
            errorText={touched.imageUrl && errors.imageUrl ? errors.imageUrl : null}
            error={touched.imageUrl && errors.imageUrl ? errors.imageUrl : null}
            ref={cropImageRef}
            path={`company/cmp_`}
            onUploadSuccess={onUploadSuccess}
            onUploadError={onUploadError}
            minCrop={IMG_UPLOAD.STORE_IMAGE.MIN_RESOLUTION}
          />
        );

        return (
          <div className="grid grid-cols-12 gap-6">
            <div className="xl:col-span-12 col-span-12">
              <div className="box">
                <div className="box-body add-products !p-0">
                  <div className="grid grid-cols-1 gap-6">
                    {renderMultiLanguageInput('Store Name', 'name', true)}
                  </div>
                  {renderFormRow([
                    <SelectBox
                      key="companyId"
                      label="Company Name"
                      name="companyId"
                      value={companyOptions.find((option) => option?.value === values.companyId)}
                      options={companyOptions}
                      onChange={(e) => {
                        if (!isEdit) {
                          let company = companyOptions.find((option) => option?.value === e.value)
                          setPhoneCode(company?.data?.phoneCode)
                          setFieldValue('companyId', e.value)
                        }
                      }}
                      errorText={errors.companyId && touched.companyId ? errors.companyId : null}
                      required
                      isDisabled={isEdit}
                    />,
                    renderInput('Email', 'email', 'text'),
                  ])}
                  {renderFormRow([
                    renderInput('Phone Number', 'phoneNumber', 'text', phoneCode, true),
                    renderInput('Outlet Code', 'outletCode', 'text', '', true),
                  ])}
                  {renderFormRow([
                    renderInput('Latitude', 'latitude', '', '', true),
                    renderInput('Longitude', 'longitude', '', '', true),
                  ])}
                  {renderFormRow([
                    <MultiLanguageInput
                      key="address"
                      label="Address"
                      name="address"
                      setFieldValue={setFieldValue}
                      values={values}
                      errors={errors}
                      touched={touched}
                      required
                    />,
                    <MultiLanguageInput
                      key="storeLocation"
                      label="Store Location"
                      name="storeLocation"
                      setFieldValue={setFieldValue}
                      values={values}
                      errors={errors}
                      touched={touched}
                      required
                    />

                  ], 3)}
                  {renderFormRow([
                    renderSelect('Services', 'services', true, SERVICE_OPTIONS, '', true),
                    <SelectBox
                      key="isClosed"
                      label="Session Time Out"
                      name="isClosed"
                      value={DEFAULT_OPTIONS_STORE.find((option) => option?.value === values.isClosed)}
                      options={DEFAULT_OPTIONS_STORE}
                      onChange={(e) => {
                        setFieldValue('isClosed', e.value)
                      }}
                      errorText={errors.isClosed && touched.isClosed ? errors.isClosed : null}
                      required
                    />
                  ])}
                  {renderFormRow([
                    renderInput('Store Open time', 'open', 'time', '', true),
                    renderInput('Store Close Time', 'close', 'time', '', true),
                  ])}
                  <h6 className='mt-6'>Delivery settings</h6>
                  {renderFormRow([
                    <InputBox
                      key="minCartValue"
                      label="Minimum Cart Value"
                      type="number"
                      min={0}
                      name="config.deliveryConfig.minCartValue"
                      value={values?.config?.deliveryConfig?.minCartValue}
                      onChange={(e) => setFieldValue('config.deliveryConfig.minCartValue', e.target.value)}
                      errorText={
                        errors.config?.deliveryConfig?.minCartValue && touched.config?.deliveryConfig?.minCartValue ? errors.config.deliveryConfig?.minCartValue : null
                      }
                      step="any"
                      required
                    />,
                    <InputBox
                      key="minDeliveryForKm"
                      label="Minimum Delivery for KM"
                      type="number"
                      min={0}
                      name="config.deliveryConfig.minDeliveryForKm"
                      value={values?.config?.deliveryConfig?.minDeliveryForKm}
                      onChange={(e) => setFieldValue('config.deliveryConfig.minDeliveryForKm', e.target.value)}
                      errorText={
                        errors.config?.deliveryConfig?.minDeliveryForKm && touched.config?.deliveryConfig?.minDeliveryForKm
                          ? errors.config.deliveryConfig.minDeliveryForKm
                          : null
                      }
                      step="any"
                      required
                    />
                  ])}
                  {renderFormRow([

                    <InputBox
                      key="minDeliveryCost"
                      label="Minimum Delivery Cost"
                      type="number"
                      min={0}
                      name="config.deliveryConfig.minDeliveryCost"
                      value={values?.config?.deliveryConfig?.minDeliveryCost}
                      onChange={(e) => setFieldValue('config.deliveryConfig.minDeliveryCost', e.target.value)}
                      errorText={
                        errors.config?.deliveryConfig?.minDeliveryCost && touched.config?.deliveryConfig?.minDeliveryCost
                          ? errors.config.deliveryConfig.minDeliveryCost
                          : null
                      }
                      step="any"
                      required
                    />,
                    <InputBox
                      key="costPerKm"
                      label="Cost per KM"
                      type="number"
                      min={0}
                      name="config.deliveryConfig.costPerKm"
                      value={values?.config?.deliveryConfig?.costPerKm}
                      onChange={(e) => setFieldValue('config.deliveryConfig.costPerKm', e.target.value)}
                      errorText={errors.config?.deliveryConfig?.costPerKm && touched.config?.deliveryConfig?.costPerKm ? errors.config.deliveryConfig.costPerKm : null}
                      required
                      step="any"
                    />
                  ])}
                  {renderFormRow([
                    <InputBox
                      key="maxDeliveryDistance"
                      label="Max Delivery Distance"
                      type="number"
                      step="any"
                      min={0}
                      name="config.deliveryConfig.maxDeliveryDistance"
                      value={values?.config?.deliveryConfig?.maxDeliveryDistance}
                      onChange={(e) => setFieldValue('config.deliveryConfig.maxDeliveryDistance', e.target.value)}
                      errorText={
                        errors.config?.deliveryConfig?.maxDeliveryDistance && touched.config?.deliveryConfig?.maxDeliveryDistance
                          ? errors.config.deliveryConfig.maxDeliveryDistance
                          : null
                      }
                      required
                    />,
                    renderSelect('Payment Type', 'paymentTypes', true, PAYMENT_OPTIONS, '', true),
                  ])}
                  <div className="mt-6 grid grid-cols-1 gap-6">
                    <label htmlFor="product-image-add" className="form-label">
                      Image<span style={{ color: 'red' }}>*</span>
                    </label>
                    {isImage ? (
                      <div className="h-1/2 w-1/2 m-auto">
                        <PreviewImage
                          src={values.imageUrl}
                          isRemovable={true}
                          onRemove={() => {
                            setFieldValue('imageUrl', '');
                            setIsImage(false)
                          }}
                        />
                      </div>
                    ) : (
                      renderCropImage()
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </OffCanvasForm >
  )

}

StoreForm.propTypes = {
  id: PropTypes.number,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.object.isRequired,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    phoneCode: PropTypes.string,
    address: PropTypes.string.isRequired,
    photo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    status: PropTypes.string.isRequired,
    outletCode: PropTypes.string.isRequired,
    deliveryCharge: PropTypes.string.isRequired,
    availableService: PropTypes.string.isRequired,
    serviceType: PropTypes.string,
    availableTenders: PropTypes.string.isRequired,
    tenderType: PropTypes.string,
    open: PropTypes.string,
    close: PropTypes.string,
    deliveryDistance: PropTypes.string.isRequired,
    pickupDistance: PropTypes.string.isRequired,
    promise: PropTypes.string.isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    isClosed: PropTypes.bool,
    location: PropTypes.shape({
      coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
    }),
    services: PropTypes.arrayOf(PropTypes.string),
    paymentTypes: PropTypes.arrayOf(PropTypes.string),
    storeContacts: PropTypes.shape({
      email: PropTypes.string,
      phone: PropTypes.string,
      phoneCode: PropTypes.string
    }),
    config: PropTypes.shape({
      deliveryConfig: PropTypes.shape({
        minCartValue: PropTypes.number.isRequired,
        minDeliveryCost: PropTypes.number.isRequired,
        minDeliveryForKm: PropTypes.number.isRequired,
        costPerKm: PropTypes.number.isRequired,
        maxDeliveryDistance: PropTypes.number.isRequired,
      }),
    }),
  }),
  isEdit: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default StoreForm
